import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import FormatUtils from '../common/formatUtils/FormatUtils';
import { logoBase64 } from "../utils/logoBase64";
import DateFormat from '../common/dateFormat/DateFormat';


export function gerarPDFProcesso(lista) {
  let soma = lista.reduce((acc, item) => {
    return acc += Number(item.receber)
  }, 0);

  //criação de um array vazio
  const linhas = [];
  lista.map(item => {
    item.itens.map(processo => {
      // criação de um array com os itens dentros, ou "filhos"
      const linha = [
        {
          text: item.nome_cliente,
          alignment: "left",
        },
        {
          text: processo.nome_status_processo,
          alignment: "center",
        },
        {
          text: processo.nome_tipo_processo,
          alignment: "center",
        },
        {
          text: DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao),
          alignment: "center",
        },
      ]
      // coloca esses array preenchido no array vazio 
      linhas.push(linha)
    })
    
  })

  const nomeArquivo = "Processo.pdf";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  
  const logo = {
    image: logoBase64,
    fit: [100, 100],
    alignment: "left",
  };
  
  const tabela = {
    table: {
       widths: ["25%", "25%", "25%", "25%"],
       headerRows: 0,
        body:[
         [
           {
             columns: [
               logo,
               {
                 text: " Despachante ",
                 style: "titulo2",
                 alignment: "left",
                 margin: [0, 5, 0, 0],
                },
                {
                 text: "Data: " + new Date().toLocaleDateString('pt-BR', { dateStyle: 'long' }),
                 style: "titulo2",
                 alignment: "left",
                 margin: [0, 5, 0, 0],
               },
             ],
             colSpan: 4,
           },
           {},
           {},
           {},
         ],
         [
           {
             text: "Cliente",
             style: "titulo",
             alignment: "center",
             margin: [0, 5, 0, 0],
           },
           {
             text: "Status",
             style: "titulo",
             alignment: "center",
             margin: [0, 5, 0, 0],
           },
           {
             text: "Tipo",
             style: "titulo",
             alignment: "center",
             margin: [0, 5, 0, 0],
           },
           {
             text: "Ultima Alteração",
             style: "titulo",
             alignment: "center",
             margin: [0, 5, 0, 0],
           },
          ],
          // esparrama o array agora não masi vazio
         ...linhas,
          [
            {
              columns: [
               
                {
                  text: " Total " ,
                  style: "titulo2",
                  alignment: "left",
                  margin: [0, 5, 0, 0],
                },
                {
                  text: "R$ " + FormatUtils.formatarValorTela(soma, 2),
                  style: "titulo2",
                  alignment: "right",
                  margin: [0, 5, 0, 0],
                },
              ],
              colSpan: 4,
            },
            {},
            {},
            {}
          ],
       
        ]
        
    },
  };

  const documento = {
    pageSize: "A4",
    pageMargins: [15, 15, 15, 0],
    info: {
      title: nomeArquivo,
    },
    content: [tabela],
    styles: { titulo: { fontSize: 11, bold: true }, titulo2: { fontSize: 13, bold: true, } },
    defaultStyle: {
      fontSize: 10,


    },
  };

  pdfMake.createPdf(documento).open();
}