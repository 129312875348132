import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


import Card from './layout/Card'
import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from '../common/widget/valueBox'
import Row from '../common/layout/row'
import InfoBox from '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

import imagemUser from '../assets/images/user.jpg';

import { } from './dashboardActions';

import {
    getLista
} from './dashboardActions';
import row from '../common/layout/row';
import { Brightness1 } from '@material-ui/icons';

class Dashboard extends Component {



    state = {

    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getLista();
    }

    getIcon(nome) {
        switch(nome) {
            case 'ENTREGUE AO CLIENTE ':
                return <i class="fas fa-people-carry"></i>;
            case 'FINALIZADO':
                return <i class="fas fa-tasks"></i>;
            case 'INDEFERIDO':
                return <i class="fas fa-thumbs-down"></i>;
            case 'INICIADO':
                return <i class="fas fa-hands-helping"></i>;
            case 'PENDENTE - ATENÇÃO!!!':
                return <i class="fas fa-exclamation-triangle"></i>;
            case 'PROTOCOLADO E-MAIL':
                return <i class="fas fa-envelope-open-text"></i>;
            case 'PROTOCOLADO SISGCORP':
                return <i class="fas fa-scroll"></i>;
            case 'SOLICITAR GT':
                return <i class="fas fa-shipping-fast"></i>;
            default:
                return null;
        }
   }

   getCorIcon(nome) {
    switch(nome) {
        case 'ENTREGUE AO CLIENTE ':
            return "#00000094";
        case 'FINALIZADO':
            return "#05120cba";
        case 'INDEFERIDO':
            return "#052311a3";
        case 'INICIADO':
            return "#0b2f149e";
        case 'PENDENTE - ATENÇÃO!!!':
            return "#195f23bf";
        case 'PROTOCOLADO E-MAIL':
            return "#1f7f37ba";
        case 'PROTOCOLADO SISGCORP':
            return "#1c4329a6";
        case 'SOLICITAR GT':
            return "#1b352594";
        default:
            return "#d32f2f";
    }
}    

getCor(nome) {
    switch(nome) {
        case 'ENTREGUE AO CLIENTE ':
            return "#040f0f";
        case 'FINALIZADO':
            return "#081e14";
        case 'INDEFERIDO':
            return "#052311eb";
        case 'INICIADO':
            return "#144921";
        case 'PENDENTE - ATENÇÃO!!!':
            return "#248232";
        case 'PROTOCOLADO E-MAIL':
            return "#2ba84a";
        case 'PROTOCOLADO SISGCORP':
            return "#2d6440eb";
        case 'SOLICITAR GT':
            return "#2d563e";
        default:
            return "#d32f2f";
    }
}        

    render() {
        
        console.log(this.props.lista);
        
                    let totalQuantidade = 0;
 



                    return (

                        <Content>

                            <Grid style={{ marginLeft: 15}}>

                                <h3><center>
                                    DASHBOARD
                                </center></h3>
                            </Grid>

                            <Row>
                                {this.props.lista.map(item => (
                                    console.log(item.cor),
                                    totalQuantidade += parseFloat(item.quantidade),
                                    <div class="col-lg-4 col-6" >
                                        <div style={{display: "flex", backgroundColor: this.getCor(item.nome), color: "#fff", marginBottom: 20, borderRadius: 5, boxShadow: "0 0 10px 2px #888888"}}>
                                            <div style={{fontSize: 40, padding: 20, maxWidth: 80, backgroundColor: this.getCorIcon(item.nome), borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                                            {this.getIcon(item.nome)}
                                            </div>
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 10}}>
                                                <h3>{item.quantidade}</h3>
                                                <h6>{item.nome}</h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div class="col-lg-4 col-6">
                                    <div style={{display: "flex", backgroundColor: "#2d3a3a", color: "#fff", marginBottom: 20, borderRadius: 5, boxShadow: "0 0 10px 2px #888888"}}>
                                        <div style={{fontSize: 40, padding: 20, maxWidth: 80, background: "#1c2323c4", borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}>
                                            <i class="fas fa-boxes"></i>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 10}}>
                                            <h3> {FormatUtils.formatarValorTela(totalQuantidade)}</h3>
                                            <h6>QUANTIDADE TOTAL</h6>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            

                        </Content >







                    )
    }
}

function mapStateToProps(state) {
    return ({
        sessao: state.auth.usuario,
        lista: state.dashboard.lista,
        modoTela: state.relatorioFinanceiro.modoTela,
        listaStatusIcon: state.dashboard.listaStatusIcon,
	    filtro: state.dashboard.filtro,

    });
}
const mapDispatchToProps = dispatch => bindActionCreators({
    getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
